import React from 'react'
import Anticon from '../Anticon'
import { Badge, Dropdown, Menu } from 'antd'
import { Link, usePage } from '@inertiajs/inertia-react'
import BellIcon from '../../../assets/icons/bell.svg'
import ChevronRightIcon from '../../../assets/icons/chevron-right.svg'
import { Inertia } from '@inertiajs/inertia'
import NotificationContent from '../notification/NotificationContent'
import './NotificationDropdown.scss'
import useZiggy from '../../hooks/useZiggy'

const NotificationDropdown = () => {
    const { getRoute } = useZiggy()
    const latest_notifications = usePage().props.latest_notifications ?? []
    const latestIds = latest_notifications.map((l) => l.id)

    const handleOpenChange = (open) => {
        if (!open && latestIds.length) {
            Inertia.visit(getRoute('notifications.read'), {
                method: 'post',
                data: { notifications: latestIds },
                preserveState: true,
                preserveScroll: true,
            })
        }
    }

    const unreadNotifications = latest_notifications.filter((l) => l.read_at === null)

    const menuItems = latest_notifications.map((notification) => ({
        label: <NotificationContent {...notification} />,
        icon: notification.read_at === null ? <Badge status="error" /> : null,
    }))
    menuItems.push({
        label: (
            <Link href={getRoute('notifications.index')} className="persistant-item">
                Voir toutes les notifications <Anticon icon={ChevronRightIcon} />
            </Link>
        ),
    })

    return (
        <Dropdown
            className="NotificationDropdown-trigger"
            overlayClassName="NotificationDropdown-dropdown"
            placement="bottom"
            overlay={<Menu className="NotificationDropdown-menu" items={menuItems} />}
            trigger={['click']}
            onOpenChange={handleOpenChange}
        >
            <button className="btn-no-ui">
                <Anticon icon={BellIcon} />

                {unreadNotifications.length !== 0 && (
                    <div className="notification">
                        <p>{unreadNotifications.length}</p>
                    </div>
                )}
            </button>
        </Dropdown>
    )
}

export default NotificationDropdown
