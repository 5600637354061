import React, { createElement } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import './Anticon.scss'

const Anticon = ({ icon, className, ...props }) => {
    const Icon = createElement(icon)
    return (
        <span role="img" className={clsx('anticon', className)} {...props}>
            {Icon}
        </span>
    )
}

Anticon.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element, PropTypes.object]).isRequired,
    className: PropTypes.string,
}

export default Anticon
