export const ROLES = {
    Accountant: 'accountant',
    AffiliateAdmin: 'affiliate_admin',
    AffiliateSale: 'affiliate_sale',
    AlurManager: 'alur_manager',
    AlurSale: 'alur_sale',
    BackOfficeUser: 'back_office_user',
    CompanyAdmin: 'company_admin',
    ConsultationAdmin: 'consultation_admin',
    InternalForce: 'internal_force',
    NotAlurManager: 'not_alur_manager',
    NotAlurSale: 'not_alur_sale',
    PromoterAdmin: 'promoter_admin',
    PromoterSale: 'promoter_sale',
    RegionUnitAdmin: 'region_unit_admin',
    RegionUnitSuperAdmin: 'region_unit_super_admin',
    WebIDAdmin: 'web_id_admin',
    RealEstateAgencyAdmin: 'real_estate_agency_admin',
    RealEstateAgencyPartner: 'real_estate_agency_partner',
}
