import React, { useEffect, useRef } from 'react'
import { usePage } from '@inertiajs/inertia-react'
import { toast, ToastContainer } from 'react-toastify'
import isString from 'lodash/isString'
import ToastIcon from './ToastIcon'
import 'react-toastify/dist/ReactToastify.css'

export default function InertiaFlashMessage() {
    const shouldSkipFlashMessage = useRef(true)

    window.onpopstate = () => {
        shouldSkipFlashMessage.current = false
    }

    const { flash } = usePage().props

    useEffect(() => {
        if (shouldSkipFlashMessage.current === false) {
            shouldSkipFlashMessage.current = true
            return
        }

        const toastFnType = {
            success: toast.success,
            error: toast.error,
            warning: toast.warning,
            info: toast.info,
        }

        Object.entries(flash).forEach(([type, message]) => {
            if (isString(message)) {
                const toastFn = toastFnType?.[type] ?? toast
                toastFn(message)
            }
        })
    }, [flash])

    return <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} theme="mpi" icon={ToastIcon} autoClose={5000} />
}
