import { Inertia } from '@inertiajs/inertia'
import NProgress from 'nprogress'
import { useEffect, useRef } from 'react'

const blacklist = ['/produits/programmes']
const progressDelay = 0

// HTML from https://ant.design/components/spin
const spin = `<div class="ant-spin ant-spin-lg ant-spin-spinning" aria-live="polite" aria-busy="true"><span class="ant-spin-dot ant-spin-dot-spin"><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span></div>`
// KEEP role="bar" https://github.com/rstacruz/nprogress#template
const template = `<div class="bar" role="bar"><div class="peg"></div></div>${spin}`

const LoadingProgress = () => {
    const timeout = useRef(undefined)

    const isBlacklisted = (event) => {
        const from = window?.location?.pathname
        const to = event?.detail?.visit?.url?.pathname
        if (!from || !to) {
            return false
        }

        return blacklist.some((page) => from === page) && blacklist.some((page) => to === page)
        // WARN peut-être une meilleure solution
        // return blacklist.some((page) => from.startsWith(page)) && blacklist.some((page) => to.startsWith(page))
    }

    useEffect(() => {
        NProgress.configure({ template })
        Inertia.on('start', (event) => {
            if (isBlacklisted(event)) {
                return
            }
            timeout.current = setTimeout(() => NProgress.start(), progressDelay)
        })

        Inertia.on('progress', (event) => {
            if (NProgress.isStarted() && event.detail.progress.percentage) {
                NProgress.set((event.detail.progress.percentage / 100) * 0.9)
            }
        })

        Inertia.on('finish', (event) => {
            if (!NProgress.isStarted()) {
                return
            } else if (event.detail.visit.completed) {
                NProgress.done()
            } else if (event.detail.visit.interrupted) {
                NProgress.set(0)
            } else if (event.detail.visit.cancelled) {
                NProgress.done()
                NProgress.remove()
            }
        })
    }, [])

    return null
}

export default LoadingProgress
