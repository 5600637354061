import React from 'react'
import Anticon from '../Anticon'
import { Button, Dropdown, Menu, Space, Typography } from 'antd'
import { Link, usePage } from '@inertiajs/inertia-react'
import UserIcon from '../../../assets/icons/user-circle.svg'
import ChevronDownIcon from '../../../assets/icons/chevron-down.svg'
import LogoutIcon from '../../../assets/icons/logout.svg'
import './AccountDropdown.scss'
import useAuthUser from '../../hooks/useAuthUser'
import useZiggy from '../../hooks/useZiggy'
import { ROLES } from '../../constants/roles'
import { Inertia } from '@inertiajs/inertia'

const ACCOUNT = 'account'
const PREFERENCES = 'preferences'
const MY_REGION_UNIT = 'my-region-unit'

const AccountDropdown = () => {
    const { items } = usePage().props
    const { user, hasRole } = useAuthUser()
    const { getRoute, ziggyRoute } = useZiggy()

    const isAllowed = (component) => {
        return Boolean(items.account.find((c) => c.item_name === component))
    }

    const hrefProfile = () => {
        Inertia.visit(getRoute('profile'), {
            method: 'get',
        })
    }

    const dashboard = ziggyRoute().current('dashboard')
    const menuItems = [
        {
            label: (
                <Space direction="vertical">
                    <Typography.Text>{[user?.firstname, user?.lastname].join(' ')}</Typography.Text>

                    <Typography.Text ellipsis>{user?.email}</Typography.Text>
                </Space>
            ),
            disabled: true,
        },
        isAllowed(ACCOUNT) && {
            label: <Link href={getRoute('my-account.edit')}>Mon compte</Link>,
        },
        isAllowed(PREFERENCES) && {
            label: (
                <Button className="btn-no-ui" style={{ height: 'auto' }} onClick={hrefProfile}>
                    Mes préférences
                </Button>
            ),
        },
        isAllowed(MY_REGION_UNIT) && {
            label: <Link href={getRoute('region-unit.edit')}>Ma caisse</Link>,
        },
        hasRole([ROLES.RegionUnitSuperAdmin, ROLES.RegionUnitAdmin]) && {
            label: (
                <a href="/settings" target="_blank">
                    Back Office
                </a>
            ),
        },
        {
            label: (
                <Link href={getRoute('logout.store')} method="post" as="button" type="button" className="btn-no-ui">
                    <Anticon icon={LogoutIcon} />
                    Se déconnecter
                </Link>
            ),
        },
    ].filter(Boolean)

    return (
        <>
            <Dropdown
                className="AccountDropdown-trigger"
                overlay={<Menu className="AccountDropdown-menu" items={menuItems} />}
                overlayClassName="AccountDropdown-dropdown"
                placement="bottomRight"
                trigger={['click']}
            >
                <button>
                    <Anticon icon={UserIcon} />

                    {dashboard && (
                        <>
                            <span className="name">Bonjour {user.firstname}</span>

                            <Anticon className="chevron" icon={ChevronDownIcon} />
                        </>
                    )}
                </button>
            </Dropdown>
        </>
    )
}

export default AccountDropdown
