import React, { Suspense, useMemo } from 'react'
import { ConfigProvider } from 'antd'
import * as Sentry from '@sentry/react'
import LoadingPage from '../pages/LoadingPage'
import Error from '../pages/Error/Error'
import frFR from 'antd/lib/locale/fr_FR'
import '../helpers/moment'
import Cookie from '../components/Cookie'
import LoadingProgress from '../components/LoadingProgress'
import ReloadPrompt from '../components/toast/ReloadPrompt'
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react'
import Analytics from '../components/Analytics'
import useCsrfToken from '../hooks/useCsrfToken'
import { usePage } from '@inertiajs/inertia-react'

const ConfigProviders = ({ children }) => {
    const { csp_nonce } = usePage().props
    useCsrfToken()

    const instance = useMemo(() => {
        if (import.meta.env.VITE_MATOMO_TRACKER_URL && import.meta.env.VITE_MATOMO_SITE_ID) {
            return createInstance({
                urlBase: import.meta.env.VITE_MATOMO_TRACKER_URL,
                siteId: import.meta.env.VITE_MATOMO_SITE_ID,
            })
        }
        return null
    }, [])

    return (
        <Sentry.ErrorBoundary fallback={(props) => <Error status={500} {...props} />}>
            <MatomoProvider value={instance}>
                <ConfigProvider locale={frFR} csp={{ nonce: csp_nonce }}>
                    <LoadingProgress />

                    <Suspense fallback={<LoadingPage />}>{children}</Suspense>

                    <Cookie />

                    <Analytics />

                    <ReloadPrompt />
                </ConfigProvider>
            </MatomoProvider>
        </Sentry.ErrorBoundary>
    )
}

export default ConfigProviders
