import React from 'react'
import { render } from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { createInertiaApp } from '@inertiajs/inertia-react'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import BaseLayout from './layouts/BaseLayout'
import 'nprogress/nprogress.css'
import 'antd/dist/antd.less'
import './main.scss'

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: [import.meta.env.VITE_APP_ENV, 'front'].join('-'),
    release: import.meta.env.SENTRY_RELEASE?.id,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
})

createInertiaApp({
    resolve: async (name) => {
        const module = await resolvePageComponent(`./pages/${name}.jsx`, import.meta.glob('./pages/**/*.jsx'))
        const page = module.default
        page.layout ??= (page) => <BaseLayout>{page}</BaseLayout>
        return page
    },
    setup({ el, App, props }) {
        render(<App {...props} />, el)
    },
})
