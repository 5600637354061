import { usePage } from '@inertiajs/inertia-react'
import isEqual from 'lodash/isEqual'
import { parse } from 'qs'
import { useEffect, useMemo, useRef } from 'react'

const decoder = (value) => {
    if (/^(\d+|\d*\.\d+)$/.test(value)) {
        return Number(value)
    }

    const keywords = {
        true: true,
        false: false,
        null: null,
        undefined: undefined,
    }
    if (value in keywords) {
        return keywords[value]
    }

    return decodeURI(value)
}

export default function usePageUrl() {
    const pageUrl = new URL(usePage().url, window.location.origin)
    const searchParams = useMemo(() => {
        return parse(pageUrl.search, { ignoreQueryPrefix: true, decoder })
    }, [pageUrl.search])
    const searchParamsRef = useRef({})

    useEffect(() => {
        searchParamsRef.current = searchParams
    }, [searchParams])

    const setSearchParams = (name, value, defaultValue) => {
        if (!isEqual(value, defaultValue)) {
            searchParamsRef.current[name] = value
        } else if (searchParamsRef.current?.[name]) {
            delete searchParamsRef.current[name]
        }
    }

    return {
        pageUrl,
        searchParams,
        searchParamsRef,
        setSearchParams,
    }
}
