import { useEffect, useMemo, useState } from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import usePageUrl from '../hooks/usePageUrl'

const Analytics = () => {
    const { pageUrl } = usePageUrl()
    const href = useMemo(() => [pageUrl.origin, pageUrl.pathname].join(''), [pageUrl])
    const [prevHref, setPrevHref] = useState(undefined)
    const { trackPageView } = useMatomo()

    useEffect(() => {
        if (href !== prevHref) {
            trackPageView({ href })
        }
        setPrevHref(href)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [href])
    return null
}

export default Analytics
