import React, { useEffect, useState } from 'react'
import './Cookie.scss'
import { Button, Col, Row, Typography } from 'antd'
import { spacing } from '../helpers/theme'
import ChevronRightIcon from '../../assets/icons/chevron-right.svg'
import PlusIcon from '../../assets/icons/plus.svg'
import Anticon from './Anticon'
import useZiggy from '../hooks/useZiggy'
import Cookies from 'js-cookie'

const Cookie = () => {
    const { getRoute } = useZiggy()
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (Cookies.get('cookie_notice_accepted') === undefined) {
            setShow(true)
        }
    }, [])

    const handleCookieTrue = () => {
        Cookies.set('cookie_notice_accepted', 'true')
        setShow(false)
    }

    const handleCookieFalse = () => {
        Cookies.set('cookie_notice_accepted', 'false')
        setShow(false)
    }

    return (
        <>
            {show && (
                <div className="cookie">
                    <Row style={{ marginBottom: spacing(4) }}>
                        <Col>
                            <Typography.Text className="wzwg">
                                Caisse d’Epargne et ses partenaires utilisent des traceurs afin de permettre le bon
                                fonctionnement de notre site, mesurer anonymement son audience, personnaliser
                                anonymement son audience, personnaliser l’assistance commerciales suite à votre
                                navigation ou encore personnaliser les annonces publicitaires diffusées sur Internet en
                                dehors de notre site et application. Vous pouvez accepter ces cookies ou continuer sans
                                accepter (valant refus) en cliquant sur les boutons de cette fenêtre. La page{' '}
                                <a target="_blank" href={getRoute('cookie-policy')} rel="noreferrer">
                                    Politique de Gestion de Cookies de votre Établissement
                                </a>{' '}
                                met à votre disposition le détail des traceurs et vous permet de revenir sur vos choix à
                                tout moment.
                            </Typography.Text>
                        </Col>
                    </Row>

                    <Row gutter={spacing(2)} justify="space-between">
                        <Col>
                            <Button type="primary" onClick={handleCookieTrue}>
                                J’accepte <Anticon icon={ChevronRightIcon} />
                            </Button>
                        </Col>

                        <Col>
                            <Button type="primary" onClick={handleCookieFalse}>
                                Continuer sans accepter <Anticon icon={ChevronRightIcon} />
                            </Button>
                        </Col>

                        <Col>
                            <a target="_blank" href={getRoute('cookie-policy')} rel="noreferrer">
                                <Button type="link">
                                    En savoir plus <Anticon icon={PlusIcon} />{' '}
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    )
}

export default Cookie
