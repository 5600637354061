import { usePage } from '@inertiajs/inertia-react'
import isArray from 'lodash/isArray'

export default function useAuthUser() {
    const { user } = usePage().props

    const hasRole = (role) => {
        if (isArray(role)) {
            return role.includes(user?.role)
        }

        return user?.role === role
    }

    return { user, hasRole }
}
