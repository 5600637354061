import React from 'react'
import { createTeleporter } from 'react-teleporter'

const LayoutHeader = createTeleporter({ multiSources: true })

export function LayoutHeaderTarget() {
    return <LayoutHeader.Target />
}

export function LayoutHeaderSource({ children }) {
    return (
        <LayoutHeader.Source multiple>
            <div>{children}</div>
        </LayoutHeader.Source>
    )
}
