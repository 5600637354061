import React, { lazy, Suspense } from 'react'
import { Skeleton } from 'antd'
import ProgramValidatedAlurSalesNotification from './Program/ProgramValidatedAlurSalesNotification'
import ProgramRefusedAlurSalesNotification from './Program/ProgramRefusedAlurSalesNotification'

const NotificationContent = ({ type, data }) => {
    const notifications = {
        'first-login': lazy(() => import('./FirstLogin')),
        'scpi-scpi-created-region-unit-admin-notification': lazy(() =>
            import('./Scpi/ScpiCreatedRegionUnitAdminNotification')
        ),
        'scpi-scpi-removed-from-region-unit-directory-notification': lazy(() =>
            import('./Scpi/ScpiRemovedFromRegionUnitDirectoryNotification')
        ),
        'management-company-company-region-unit-updated-region-unit-admin-notification': lazy(() =>
            import('./ManagementCompany/CompanyRegionUnitUpdatedRegionUnitAdminNotification')
        ),
        'scpi-scpi-added-to-region-unit-directory-notification': lazy(() =>
            import('./Scpi/ScpiAddedToRegionUnitDirectoryNotification')
        ),
        'scpi-scpi-validated-region-unit-admin-notification': lazy(() =>
            import('./Scpi/ScpiValidatedRegionUnitAdminsNotification')
        ),
        'scpi-scpi-validated-consultation-admin-notification': lazy(() =>
            import('./Scpi/ScpiValidatedConsultationAdminsNotification')
        ),
        'scpi-scpi-documents-updated-notification': lazy(() => import('./Scpi/DocumentsUpdatedNotification')),
        'scpi-scpi-adjourned-notification': lazy(() => import('./Scpi/AdjournedScpiNotification')),
        'scpi-scpi-refused-notification': lazy(() => import('./Scpi/RefusedScpiNotification')),
        'scpi-scpi-suspended-notification': lazy(() => import('./Scpi/SuspendedScpiNotification')),
        'scpi-scpi-reactivated-notification': lazy(() => import('./Scpi/ScpiReactivatedNotification')),
        'scpi-scpi-archived-notification': lazy(() => import('./Scpi/ScpiArchivedNotification')),
        'scpi-scpi-region-unit-changed-notification': lazy(() => import('./Scpi/ScpiRegionUnitChangedNotification')),
        'subscription-form-validated-notification': lazy(() =>
            import('./ScpiSubscriptionForm/SubscriptionFormValidatedNotification')
        ),
        'scpi-management-form-scpi-management-form-waiting-physical-file-notification': lazy(() =>
            import('./ManagementForm/ScpiManagementFormWaitingPhysicalFileNotification')
        ),
        'scpi-invoice-scpi-invoice-created-notification': lazy(() =>
            import('./Invoices/ScpiInvoiceCreatedNotification')
        ),
        'scpi-invoice-scpi-invoice-region-unit-updated-notification': lazy(() =>
            import('./Invoices/ScpiInvoiceRegionUnitUpdatedNotification')
        ),
        'scpi-invoice-scpi-invoice-previous-region-unit-updated-notification': lazy(() =>
            import('./Invoices/ScpiInvoicePreviousRegionUnitUpdatedNotification')
        ),
        'scpi-invoice-scpi-invoice-waiting-for-payment-notification': lazy(() =>
            import('./Invoices/ScpiInvoiceWaitingForPaymentNotification')
        ),
        'scpi-invoice-scpi-invoice-validate-notification': lazy(() =>
            import('./Invoices/ScpiInvoiceValidateNotification')
        ),
        're-invoice-60-days-payment-reminder-notification': lazy(() =>
            import('./Invoices/ReInvoice60DaysPaymentReminderNotification')
        ),
        're-invoice-90-days-payment-reminder-notification': lazy(() =>
            import('./Invoices/ReInvoice90DaysPaymentReminderNotification')
        ),
        'scpi-invoice-scpi-invoice-60-days-payment-reminder-notification': lazy(() =>
            import('./Invoices/ScpiInvoice60DaysPaymentReminderNotification')
        ),
        'scpi-invoice-scpi-invoice-90-days-payment-reminder-notification': lazy(() =>
            import('./Invoices/ScpiInvoice90DaysPaymentReminderNotification')
        ),
        'scpi-management-form-scpi-management-form-dropped-notification': lazy(() =>
            import('./ManagementForm/ScpiManagementFormDroppedNotification')
        ),
        'scpi-management-form-scpi-management-form-not-compliant-notification': lazy(() =>
            import('./ManagementForm/ScpiManagementFormNotCompliantNotification')
        ),
        'scpi-management-form-scpi-management-form-not-compliant-reminder-notification': lazy(() =>
            import('./ManagementForm/ScpiManagementFormNotCompliantReminderNotification')
        ),
        'scpi-management-form-scpi-management-form-approved-notification': lazy(() =>
            import('./ManagementForm/ScpiManagementFormApprovedNotification')
        ),
        'scpi-management-form-scpi-management-form-sale-feedback-notification': lazy(() =>
            import('./ManagementForm/ScpiManagementFormSaleFeedbackNotification')
        ),
        'scpi-management-form-scpi-management-form-sent-notification': lazy(() =>
            import('./ManagementForm/ScpiManagementFormSentNotification')
        ),
        'scpi-reinvoice-scpi-reinvoice-waiting-for-payment-notification': lazy(() =>
            import('./ReInvoices/ScpiReinvoiceWaitingForPaymentNotification')
        ),
        'program-program-reallocated-notification': lazy(() => import('./Program/ProgramReallocatedNotification')),
        'intermediary-relay-form-intermediary-relay-form-created-notification': lazy(() =>
            import('./RelayForms/IntermediaryRelayFormCreatedNotification')
        ),
        'intermediary-relay-form-intermediary-relay-form-handled-notification': lazy(() =>
            import('./RelayForms/IntermediaryRelayFormHandledNotification')
        ),
        'relay-form-relay-form-late-signature-notification': lazy(() =>
            import('./RelayForms/RelayFormLateSignatureNotification')
        ),
        'program-program-created-region-unit-admin-notification': lazy(() =>
            import('./Program/ProgramCreatedRegionUnitAdminNotification')
        ),
        'program-program-created-promoter-notification': lazy(() =>
            import('./Program/ProgramCreatedPromoterNotification')
        ),
        'program-program-validated-promoter-contacts-notification': lazy(() =>
            import('./Program/ProgramValidatedPromoterContactsNotification')
        ),
        'program-program-validated-alur-sales-notification': lazy(() =>
            import('./Program/ProgramValidatedAlurSalesNotification')
        ),
        'program-program-refused-promoter-admins-notification': lazy(() =>
            import('./Program/ProgramRefusedPromoterContactsNotification')
        ),
        'program-program-refused-alur-sales-notification': lazy(() =>
            import('./Program/ProgramRefusedAlurSalesNotification')
        ),
        'program-program-adjourned-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramAdjournedRegionUnitAdminsNotification')
        ),
        'program-program-adjourned-promoter-admins-notification': lazy(() =>
            import('./Program/ProgramAdjournedPromoterContactsNotification')
        ),
        'program-program-refused-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramRefusedRegionUnitAdminsNotification')
        ),
        'program-program-added-to-region-unit-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramAddedToRegionUnitRegionUnitAdminsNotification')
        ),
        'program-program-added-to-region-unit-promoter-contacts-notification': lazy(() =>
            import('./Program/ProgramAddedToRegionUnitPromoterContactsNotification')
        ),
        'program-program-removed-from-region-unit-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramRemovedFromRegionUnitRegionUnitAdminsNotification')
        ),
        'program-program-removed-from-region-unit-promoter-contacts-notification': lazy(() =>
            import('./Program/ProgramRemovedFromRegionUnitPromoterContactsNotification')
        ),
        'program-program-updated-grid-notification': lazy(() => import('./Program/ProgramUpdatedGridNotification')),
        'program-program-set-to-out-feed-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramSetToOutFeedRegionUnitAdminsNotification')
        ),
        'program-program-set-to-feed-back-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramSetToFeedBackRegionUnitAdminsNotification')
        ),
        'program-program-suspended-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramSuspendedRegionUnitAdminsNotification')
        ),
        'program-program-suspended-promoter-admins-notification': lazy(() =>
            import('./Program/ProgramSuspendedPromoterAdminsNotification')
        ),
        'program-program-reactivated-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramReactivatedRegionUnitAdminsNotification')
        ),
        'program-program-reactivated-promoter-admins-notification': lazy(() =>
            import('./Program/ProgramReactivatedPromoterAdminsNotification')
        ),
        'program-program-archiving-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramArchivingRegionUnitAdminsNotification')
        ),
        'program-program-reallocated-new-region-unit-promoter-admins-notification': lazy(() =>
            import('./Program/ProgramReallocatedNewRegionUnitPromoterAdminsNotification')
        ),
        'program-enabled-gpcera-promoter-users-notification': lazy(() =>
            import('./Program/ProgramEnabledGPCeraPromoterContactsNotification')
        ),
        'program-enabled-gpcera-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramEnabledGPCeraRegionUnitAdminsNotification')
        ),
        'program-sent-add-to-local-invitation-notification': lazy(() =>
            import('./Program/ProgramSentAddToLocalInvitationNotification')
        ),
        'promoter-incomplete-reminder-notification': lazy(() =>
            import('./Promoter/PromoterCreationIncompleteNotification')
        ),
        'program-enabled-local-promoters-contacts-notification': lazy(() =>
            import('./Program/ProgramEnabledLocalPromoterContactsNotification')
        ),
        'program-enabled-local-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramEnabledLocalRegionUnitAdminsNotification')
        ),
        'program-program-validated-region-unit-admins-notification': lazy(() =>
            import('./Program/ProgramValidatedRegionUnitAdminsNotification')
        ),
        'program-program-validated-consultant-admins-notification': lazy(() =>
            import('./Program/ProgramValidatedConsultationAdminsNotification')
        ),
        'relay-form-relay-form-created-from-intermediary-notification': lazy(() =>
            import('./RelayForms/RelayFormCreatedFromIntermediaryNotification')
        ),
        'relay-form-relay-form-sent-to-promoter-notification': lazy(() =>
            import('./RelayForms/RelayFormSentToPromoterNotification')
        ),
        'relay-form-digitally-signed-notification': lazy(() =>
            import('./RelayForms/RelayFormDigitallySignedNotification')
        ),
        'affiliate-invoice-created-notification': lazy(() => import('./Invoices/AffiliateInvoiceCreatedNotification')),
        'affiliate-invoice-affiliate-invoice-validated-accountants-notification': lazy(() =>
            import('./Invoices/AffiliateInvoiceValidatedAccountantsNotification')
        ),
        'affiliate-invoice-affiliate-invoice-validated-affiliate-admins-notification': lazy(() =>
            import('./Invoices/AffiliateInvoiceValidatedAffiliateAdminsNotification')
        ),
        'management-form-updated-after-credit-invoice-notification': lazy(() =>
            import('./ManagementForm/ManagementFormUpdatedAfterCreditInvoiceNotification')
        ),
        'new-invoice-credit-accountant-notification': lazy(() =>
            import('./Invoices/NewInvoiceCreditAccountantNotification')
        ),
        'new-invoice-credit-promoter-notification': lazy(() =>
            import('./Invoices/NewInvoiceCreditPromoterNotification')
        ),
        'invoices-new-reinvoice-owner-region-unit-admin-notification': lazy(() =>
            import('./ReInvoices/InvoicesNewReinvoiceOwnerRegionUnitAdminNotification')
        ),
        'invoices-real-estate-invoice60-days-payment-reminder-notification': lazy(() =>
            import('./Invoices/RealEstateInvoice60DaysPaymentReminderNotification')
        ),
        'invoices-real-estate-invoice90-days-payment-reminder-notification': lazy(() =>
            import('./Invoices/RealEstateInvoice90DaysPaymentReminderNotification')
        ),
        'invoices-affiliate-invoice60-days-payment-reminder-notification': lazy(() =>
            import('./Invoices/AffiliateInvoice60DaysPaymentReminderNotification')
        ),
        'invoices-affiliate-invoice90-days-payment-reminder-notification': lazy(() =>
            import('./Invoices/AffiliateInvoice90DaysPaymentReminderNotification')
        ),
        'management-form-management-form-reserved-alur-sale-notification': lazy(() =>
            import('./ManagementForm/ManagementFormReservedAlurSaleNotification')
        ),
        'management-form-management-form-reserved-promoter-sale-notification': lazy(() =>
            import('./ManagementForm/ManagementFormReservedPromoterSaleNotification')
        ),
        'management-form-management-form-acted-alur-sale-notification': lazy(() =>
            import('./ManagementForm/ManagementFormActedAlurSaleNotification')
        ),
        'management-form-management-form-acted-promoter-sale-notification': lazy(() =>
            import('./ManagementForm/ManagementFormActedPromoterSaleNotification')
        ),
        'management-form-management-form-invoiced-promoter-admins-notification': lazy(() =>
            import('./ManagementForm/ManagementFormInvoicedPromoterAdminsNotification')
        ),
        'management-form-management-form-acted-accountants-notification': lazy(() =>
            import('./ManagementForm/ManagementFormActedAccountantsNotification')
        ),
        'management-form-management-form-ready-to-invoice-accountants-notification': lazy(() =>
            import('./ManagementForm/ManagementFormReadyToInvoiceAccountantsDatabaseNotification')
        ),
        'management-form-management-form-invoiced-accountants-notification': lazy(() =>
            import('./ManagementForm/ManagementFormActedAccountantsNotification')
        ),
        'management-form-management-form-customer-withdrawal-alur-sale-notification': lazy(() =>
            import('./ManagementForm/ManagementFormCustomerWithdrawalAlurSaleNotification')
        ),
        'management-form-management-form-customer-withdrawal-promoter-sale-notification': lazy(() =>
            import('./ManagementForm/ManagementFormCustomerWithdrawalPromoterSaleNotification')
        ),
        'management-form-management-form-dropped-notification': lazy(() =>
            import('./ManagementForm/ManagementFormDroppedNotification')
        ),
        'management-form-promoter-sale-updated': lazy(() =>
            import('./ManagementForm/ManagementFormPromoterSaleUpdatedNotification')
        ),
        'procedure-updated-notification': lazy(() => import('./ProcedureUpdatedNotification')),
        'real-estate-agency-created-notification': lazy(() =>
            import('./RealEstateAgency/RealEstateAgencyCreatedNotification')
        ),
        'real-estate-agency-refused-region-unit-admins-notification': lazy(() =>
            import('./RealEstateAgency/RealEstateAgencyRefusedRegionUnitAdminsNotification')
        ),
        'real-estate-agency-validated-region-unit-admins-notification': lazy(() =>
            import('./RealEstateAgency/RealEstateAgencyValidatedRegionUnitAdminsNotification')
        ),
    }

    if (notifications?.[type]) {
        const NotificationContentComponent = notifications[type]

        return (
            <Suspense fallback={<Skeleton title={false} paragraph={{ rows: 1 }} active />}>
                <NotificationContentComponent {...data} />
            </Suspense>
        )
    }

    return type
}

export default NotificationContent
