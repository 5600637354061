import { useEffect } from 'react'
import { usePage } from '@inertiajs/inertia-react'
import axios from 'axios'

export default function useCsrfToken() {
    const { csrf_token } = usePage().props

    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf_token
        axios.defaults.withCredentials = true
    }, [csrf_token])
}
