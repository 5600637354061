import React from 'react'
import { toast } from 'react-toastify'
import InfoIcon from '../../../assets/icons/info.svg'
import SuccessIcon from '../../../assets/icons/success.svg'
import ErrorIcon from '../../../assets/icons/error.svg'
import Anticon from '../Anticon'

const ToastIcon = ({ type, ...props }) => {
    switch (type) {
        case toast.TYPE.INFO:
            return <Anticon icon={InfoIcon} {...props} />
        case toast.TYPE.ERROR:
            return <Anticon icon={ErrorIcon} {...props} />
        case toast.TYPE.SUCCESS:
            return <Anticon icon={SuccessIcon} {...props} />
        default:
            return null
    }
}

export default ToastIcon
