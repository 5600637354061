import React from 'react'
import { Breadcrumb, Layout } from 'antd'
import './BaseLayout.scss'
import { Link, usePage } from '@inertiajs/inertia-react'
import { LayoutHeaderTarget } from '../teleporters/LayoutHeader'
import InertiaFlashMessage from '../components/toast/InertiaFlashMessage'
import LeftMenu from '../components/layout/LeftMenu'
import ConfigProviders from '../providers/ConfigProviders'
import clsx from 'clsx'
import AdminDropdown from '../components/layout/AdminDropdown'
import NotificationDropdown from '../components/layout/NotificationDropdown'
import AccountDropdown from '../components/layout/AccountDropdown'
import Container from '../components/layout/Container'
import useZiggy from '../hooks/useZiggy'

const BaseLayout = ({ children }) => {
    const { ziggyRoute, getRoute } = useZiggy()
    const dashboard = ziggyRoute().current('dashboard')
    const { breadcrumb } = usePage().props
    return (
        <ConfigProviders>
            <Layout className="base-layout">
                <LeftMenu />

                <Layout>
                    <Layout.Header className={clsx({ 'header-dashboard': dashboard })}>
                        {breadcrumb && (
                            <Breadcrumb separator=">">
                                {breadcrumb.map((crumb) => (
                                    <Breadcrumb.Item key={crumb.url}>
                                        {crumb.url ? <Link href={crumb.url}>{crumb.title}</Link> : crumb.title}
                                    </Breadcrumb.Item>
                                ))}
                            </Breadcrumb>
                        )}

                        <LayoutHeaderTarget />

                        <div className="header-nav">
                            <AdminDropdown />

                            <NotificationDropdown />

                            <AccountDropdown />
                        </div>
                    </Layout.Header>

                    <Layout.Content>{children}</Layout.Content>

                    <Layout.Footer>
                        <Container>
                            <hr />

                            <nav>
                                <ul>
                                    <li>
                                        <Link href={getRoute('legal-notice')}>Mentions légales</Link>
                                    </li>

                                    <li>
                                        <Link href={getRoute('cookie-policy')}>
                                            Politique de gestion de cookies de votre établissement
                                        </Link>
                                    </li>

                                    <li>
                                        <Link href={getRoute('terms-service')}>Conditions générales d’utilisation</Link>
                                    </li>

                                    <li>Copyright {new Date().getFullYear()}</li>
                                </ul>
                            </nav>
                        </Container>
                    </Layout.Footer>
                </Layout>

                <InertiaFlashMessage />
            </Layout>
        </ConfigProviders>
    )
}

export default BaseLayout
