import Router from 'ziggy/Router'
import { usePage } from '@inertiajs/inertia-react'

export default function useZiggy() {
    const { routes } = usePage().props

    const ziggyRoute = (name, params, absolute) => {
        const router = new Router(name, params, absolute, routes)
        return name ? router.toString() : router
    }

    const getRoute = (name, params = {}) => {
        if (!name) {
            return null
        }
        try {
            return ziggyRoute(name, params)
        } catch (e) {
            console.warn(`route "${name}" does not exists`)
            return null
        }
    }

    return { ziggyRoute, getRoute }
}
